import React, { useEffect } from 'react';
import {
  Box,
  Text,
  Grid,
  Card,
  Stack,
  Badge,
  Inline,
  Button,
  Banner,
  Popover,
  Tooltip,
  MediaCard,
  ActionList,
  IndexTable,
  LegacyCard,
  Collapsible,
  VideoThumbnail,
  SkeletonBodyText,
  SkeletonPage,
  TextContainer,
  SkeletonDisplayText,
  Modal,
  Link
} from '@shopify/polaris';
import {
  ChevronDownMinor,
  MobileHorizontalDotsMajor,
  ChevronUpMinor
} from '@shopify/polaris-icons';
import { useState } from 'react';
import { ResponseDto, vendorOverviewDto } from '../../../dto/response';
import statusCodes from '../../../utils/status.codes';
import { apiRoutes } from '../../../utils/api.routes';
import { useRequest } from '../../../hooks/useRequest';
import { bannerDto, vendorListDto } from '../../../dto/types';
import { useNavigate } from 'react-router-dom';

export const Dashboard: React.FC = () => {
  const [open, setOpen] = useState<boolean[]>([]);
  const [pageLoading, setPageLoading] = useState(true);
  const [overViewLoading, setOverViewLoading] = useState(true);
  const [bannerVisible, setBannerVisible] = useState(true);
  const [listLoading, setListLoading] = useState(true);
  const [vendorLoading, setVendorLoading] = useState<boolean[]>([]);
  const [thumbnail, setThumbnail] = useState(true);
  const [vendorPopoverActive, setVendorPopoverActive] = useState<boolean[]>([]);
  const [vendorClickActive, setVendorClickActive] = useState<boolean[]>([]);
  const [campaignClickActive, setCampaignClickActive] = useState<boolean[]>([]);
  const [campaignPopoverActive, setCampaignPopoverActive] = useState<boolean[]>([]);
  const [vendorOverview, setVendorOverview] = useState<vendorOverviewDto>({
    active_vendors: 0,
    active_list: 0,
    sync_customers: 0
  });
  const [vendorList, setVendorList] = useState<Array<vendorListDto>>([]);
  const [campaignList, setCampaignList] = useState<Array<any>>([]);
  const [vendorBanner, setVendorBanner] = useState<Array<bannerDto>>([]);
  const [confirmPopupActive, setConfirmPopupActive] = useState<boolean>(false);
  const [confirmPopupMessage, setConfirmPopupMessage] = useState('');
  const [confirmPopupLoader, setConfirmPopupLoader] = useState<boolean>(false);
  const [confirmPopupData, setConfirmPopupData] = useState({
    type: '',
    index: 0,
    index2: 0,
    campaign_id: '',
    status: '',
    vendor_id: '',
    vendor: ''
  });

  const { get, destroy, put, post } = useRequest();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const chargeId: string | null = queryParams.get('charge_id');
  let chargeSend = true;
  let oneRequest = true;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (chargeId == null && chargeSend) {
      initDashboard();
    } else {
      setBannerVisible(false);
      createSubscription(chargeId);
      chargeSend = false;
    }
  }, [chargeId]);

  const createSubscription = (chargeId: string | null) => {
    if (chargeSend) {
      post(apiRoutes.createSubscription, { chargeId: chargeId })
        .then((response: ResponseDto) => {
          setBannerVisible(true);
          initDashboard();
        })
        .catch((error) => {
          initDashboard();
        });
    }
  };

  const trackEvent = () => {
    if (oneRequest) {
      post(apiRoutes.trackMixPanel, { event: 'Dashboard Page' }).then(
        (response: ResponseDto) => {}
      );
      oneRequest = false;
    }
  };

  const initDashboard = () => {
    trackEvent();
    get(apiRoutes.authCheck).then((response: ResponseDto) => {
      if (response.status == statusCodes.SUCCESS) {
        if (!response.data.data.onBoarding) {
          navigate('/welcome', {
            state: location.pathname
          });
        } else {
          setPageLoading(false);
        }
      }
    });

    overViewUpdate();

    get(apiRoutes.vendorList).then((response: ResponseDto) => {
      if (response.status == statusCodes.SUCCESS) {
        const vendors: Array<vendorListDto> = response.data.data;
        const vendorsData = [];
        if (vendors.length > 0) {
          const banners = [...vendorBanner];
          for (const vendor of vendors) {
            if (vendor.campaignCount > 0) {
              vendorsData.push(vendor);
            } else {
              banners.push({ vendor: vendor.vendor });
            }
          }
          setVendorBanner(banners);
          setVendorList(vendorsData);
          handleToggle(vendorsData[0]._id, 0, vendorsData[0].vendor);
          setListLoading(false);
        }
      }
    });
  };

  const overViewUpdate = () => {
    setOverViewLoading(true);
    get(apiRoutes.dashboardOverview).then((response: ResponseDto) => {
      if (response.status == statusCodes.SUCCESS) {
        setVendorOverview(response.data.data);
        setOverViewLoading(false);
      }
    });
  };

  const resourceName = {
    singular: 'campaign',
    plural: 'campaigns'
  };

  const togglePopoverActive = (index: number) => {
    const popArray = [...vendorPopoverActive];
    popArray[index] = !popArray[index];
    setVendorPopoverActive(popArray);
  };

  const toggleChildPopoverActive = (index: number) => {
    const popArray = [...campaignPopoverActive];
    popArray[index] = !popArray[index];
    setCampaignPopoverActive(popArray);
  };

  const vendorDeleteHandle = (index: number, vendorId: string) => {
    togglePopoverActive(index);
    const clickActive = [...vendorClickActive];
    clickActive[index] = true;
    setVendorClickActive(clickActive);
    destroy(apiRoutes.vendorDelete, { vendorId: vendorId })
      .then((response: ResponseDto) => {
        if (response.status == statusCodes.SUCCESS) {
          let vendorArray = [...vendorList];
          vendorArray = [...vendorArray.slice(0, index), ...vendorArray.slice(index + 1)];
          setVendorList(vendorArray);
          const clickActive = [...vendorClickActive];
          clickActive[index] = false;
          setVendorClickActive(clickActive);
          if (vendorArray.length == 0) {
            navigate('/welcome', {
              state: location.pathname
            });
          }
        }
        changeVendorLoading(index, false);
        overViewUpdate();
      })
      .catch((error) => {
        changeVendorLoading(index, false);
      });
  };

  const changeVendorLoading = (index: number, state: boolean) => {
    const vendorArr = [...vendorLoading];
    vendorArr[index] = state;
    setVendorLoading(vendorArr);
  };

  const vendorStatusHandle = (index: number, vendorId: string, status: string) => {
    togglePopoverActive(index);
    const clickActive = [...vendorClickActive];
    clickActive[index] = true;
    setVendorClickActive(clickActive);
    status = status == 'active' ? 'inactive' : 'active';
    put(apiRoutes.vendorStatus, { vendorId: vendorId, status: status }).then(
      (response: ResponseDto) => {
        if (response.status == statusCodes.SUCCESS) {
          const vendorArray = [...vendorList];
          vendorArray[index].status = status;
          setVendorList(vendorArray);
          const clickActive = [...vendorClickActive];
          clickActive[index] = false;
          setVendorClickActive(clickActive);
        }
      }
    );
  };

  const campaignStatusHandle = (
    index: number,
    index2: number,
    campaignId: string,
    status: string
  ) => {
    toggleChildPopoverActive(index2 + index);
    const clickActive = [...campaignClickActive];
    clickActive[index2 + index] = true;
    setCampaignClickActive(clickActive);
    setConfirmPopupLoader(true);
    status = status == 'active' ? 'inactive' : 'active';
    put(apiRoutes.campaignStatus, { campaignId: campaignId, status: status }).then(
      (response: ResponseDto) => {
        if (response.status == statusCodes.SUCCESS) {
          const campaignArray = [...campaignList];
          campaignArray[index][index2].status = status;
          setCampaignList(campaignArray);
          clickActive[index2 + index] = false;
          setCampaignClickActive(clickActive);
          setConfirmPopupLoader(false);
          setConfirmPopupActive(false);
        }
      }
    );
  };

  const campaignDeleteHandle = (
    index: number,
    index2: number,
    campaignId: string,
    vendorId: string,
    vendor: string
  ) => {
    toggleChildPopoverActive(index2 + index);
    const clickActive = [...campaignClickActive];
    clickActive[index2 + index] = true;
    setCampaignClickActive(clickActive);
    setConfirmPopupLoader(true);
    destroy(apiRoutes.campaignDelete, { campaignId: campaignId }).then((response: ResponseDto) => {
      if (response.status == statusCodes.SUCCESS) {
        campaignList.splice(index, 1);
        setCampaignList(campaignList);
        //if (!campaignList.length) {
        const vendors = vendorList.filter((x: vendorListDto) => x._id !== vendorId);
        vendorBanner.push({ vendor: vendor });
        setVendorBanner(vendorBanner);
        setVendorList(vendors);
        if (vendors.length > 0) {
          open[0] = true;
          getVendorDetail(vendors[0]._id, 0, vendors[0].vendor);
        }
        //}
        clickActive[index2 + index] = false;
        setCampaignClickActive(clickActive);
        setConfirmPopupLoader(false);
        setConfirmPopupActive(false);
        overViewUpdate();
      }
    });
  };

  const getVendorDetail = (vendorID: string, index: number, vendor: string) => {
    changeVendorLoading(index, true);
    get(apiRoutes.vendorFind + `/${vendorID}`)
      .then((response: ResponseDto) => {
        if (response.status == statusCodes.SUCCESS) {
          const campaignArray = [...campaignList];
          campaignArray[index] = response.data.data;
          setCampaignList(campaignArray);
        }
        changeVendorLoading(index, false);
      })
      .catch((error) => {
        changeVendorLoading(index, false);
      });
  };

  const handleToggle = (vendorID: string, index: number, vendor: string) => {
    const openArray = [...open];
    openArray[index] = !openArray[index];
    getVendorDetail(vendorID, index, vendor);
    setOpen(openArray);
  };

  const closeConfirmPopup = () => {
    setConfirmPopupActive(false);
  };
  const triggerModalEvent = () => {
    if (confirmPopupData.type == 'status') {
      campaignStatusHandle(
        confirmPopupData.index,
        confirmPopupData.index2,
        confirmPopupData.campaign_id,
        confirmPopupData.status
      );
    }

    if (confirmPopupData.type == 'destroy') {
      campaignDeleteHandle(
        confirmPopupData.index,
        confirmPopupData.index2,
        confirmPopupData.campaign_id,
        confirmPopupData.vendor_id,
        confirmPopupData.vendor
      );
    }
  };

  const rowMarkup = (campaigns: any, index: number, vendor: vendorListDto) => {
    if (!campaigns) {
      return null;
    }
    return campaigns.map((campaign: any, index2: number) => (
      <IndexTable.Row id={campaign.id} key={campaign.id} position={index2}>
        <IndexTable.Cell>
          <Text variant="bodyMd" fontWeight="bold" as="span">
            {campaign.name}
          </Text>
        </IndexTable.Cell>
        <IndexTable.Cell>{campaign.customer_count}</IndexTable.Cell>
        <IndexTable.Cell>
          <Inline align="end">
            <Badge status={campaign.status == 'active' ? 'success' : 'critical'}>
              {campaign.status}
            </Badge>
          </Inline>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <Inline align="end">
            <Popover
              active={campaignPopoverActive[index2 + index]}
              onClose={() => toggleChildPopoverActive(index2 + index)}
              activator={
                <Button
                  plain
                  onClick={() => toggleChildPopoverActive(index2 + index)}
                  loading={campaignClickActive[index2 + index] || false}
                  disabled={campaignClickActive[index2 + index] || false}
                  icon={MobileHorizontalDotsMajor}></Button>
              }>
              <ActionList
                actionRole="menuitem"
                items={[
                  {
                    content: `${campaign.status == 'inactive' ? 'Active' : 'InActive'}`,
                    onAction: () => {
                      setConfirmPopupMessage(
                        `Are you sure you want to  ${
                          campaign.status == 'inactive' ? 'Active' : 'InActive'
                        } Campaign?`
                      );
                      setConfirmPopupActive(true);
                      setConfirmPopupData({
                        type: 'status',
                        index: index,
                        index2: index2,
                        campaign_id: campaign.id,
                        status: campaign.status,
                        vendor_id: '',
                        vendor: ''
                      });
                    }
                  },
                  {
                    content: 'Edit',
                    onAction: () =>
                      navigate(`/vendor/add/?vendor=${vendor.vendor}`, { state: location.pathname })
                  },
                  {
                    content: 'Remove',
                    onAction: () => {
                      setConfirmPopupMessage('Are you sure you want to Remove Campaign?');
                      setConfirmPopupActive(true);
                      setConfirmPopupData({
                        type: 'destroy',
                        index: index,
                        index2: index2,
                        campaign_id: campaign.id,
                        vendor_id: vendor._id,
                        vendor: vendor.vendor,
                        status: ''
                      });
                    }
                  }
                ]}
              />
            </Popover>
          </Inline>
        </IndexTable.Cell>
      </IndexTable.Row>
    ));
  };

  return (
    <>
      {pageLoading ? (
        <SkeletonPage>
          <LegacyCard sectioned>
            <SkeletonBodyText />
          </LegacyCard>
          <LegacyCard sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
          </LegacyCard>
          <LegacyCard sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
          </LegacyCard>
          <LegacyCard>
            <LegacyCard.Section>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={2} />
              </TextContainer>
            </LegacyCard.Section>
            <LegacyCard.Section>
              <SkeletonBodyText lines={1} />
            </LegacyCard.Section>
          </LegacyCard>
          <LegacyCard subdued>
            <LegacyCard.Section>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={2} />
              </TextContainer>
            </LegacyCard.Section>
            <LegacyCard.Section>
              <SkeletonBodyText lines={2} />
            </LegacyCard.Section>
          </LegacyCard>
        </SkeletonPage>
      ) : (
        <>
          <Grid>
            <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 6, lg: 8, xl: 8 }}>
              <Text variant="heading2xl" as="h1">
                Welcome back!
              </Text>
            </Grid.Cell>
            <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 6, lg: 4, xl: 4 }}>
              <div style={{ textAlign: 'right' }}>
                <Button
                  primary
                  onClick={() => {
                    navigate('/vendor/add', { state: location.pathname });
                  }}>
                  Edit email marketing service
                </Button>
              </div>
            </Grid.Cell>
          </Grid>
          {vendorBanner.map((banner: bannerDto, index) => (
            <>
              <div style={{ marginTop: 'var(--p-space-2)' }}></div>
              <Banner status="warning">
                <p>
                  <strong>Congratulations</strong> on connecting to {banner.vendor}!{' '}
                </p>
                <p>
                  Just one more step to start email sync:
                  <Button
                    plain
                    monochrome
                    onClick={() => {
                      navigate(`/vendor/add?vendor=${banner.vendor}`, { state: location.pathname });
                    }}>
                    Click Here
                  </Button>{' '}
                  to select a list from the dropdown and save.
                </p>
              </Banner>
              <div style={{ marginTop: 'var(--p-space-4)' }}></div>
            </>
          ))}
          <div style={{ marginBottom: 'var(--p-space-4)' }}></div>
          {!overViewLoading ? (
            <Grid>
              <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}>
                <Card sectioned>
                  <Text variant="headingMd" as="h2">
                    <span
                      style={{
                        color: 'var(--p-text-subdued)',
                        fontWeight: 'var(--p-font-weight-semibold)'
                      }}>
                      Total synchronized customers
                    </span>
                    <Tooltip
                      preferredPosition={'above'}
                      content="Number of customers who have been synchronized through the app.">
                      <span
                        style={{
                          color: 'var(--p-text-subdued)',
                          fontWeight: 'var(--p-font-weight-semibold)',
                          border: 'var(--p-border-divider)',
                          borderRadius: 'var(--p-border-radius-half)',
                          marginLeft: 'var(--p-space-2)',
                          width: 'var(--p-space-5)',
                          display: 'inline-block',
                          verticalAlign: 'middle',
                          textAlign: 'center',
                          lineHeight: '16px',
                          fontSize: '10px'
                        }}>
                        ?
                      </span>
                    </Tooltip>
                  </Text>
                  <Stack distribution="fillEvenly">
                    <div
                      style={{
                        fontSize: 'var(--p-font-size-24)',
                        fontWeight: 'var(--p-font-weight-bold)',
                        lineHeight: 'var(--p-line-height-7)',
                        fontFamily: 'var(--p-font-family-mono)',
                        marginTop: 'var(--p-space-3)'
                      }}>
                      {vendorOverview.sync_customers}
                    </div>
                  </Stack>
                </Card>
              </Grid.Cell>
              <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}>
                <Card sectioned>
                  <Text variant="headingMd" as="h2">
                    <span
                      style={{
                        color: 'var(--p-text-subdued)',
                        fontWeight: 'var(--p-font-weight-semibold)'
                      }}>
                      Total email lists
                    </span>
                    <Tooltip
                      preferredPosition={'above'}
                      content="The app is currently hosting following number of active email marketing lists.">
                      <span
                        style={{
                          color: 'var(--p-text-subdued)',
                          fontWeight: 'var(--p-font-weight-semibold)',
                          border: 'var(--p-border-divider)',
                          borderRadius: 'var(--p-border-radius-half)',
                          marginLeft: 'var(--p-space-2)',
                          width: 'var(--p-space-5)',
                          display: 'inline-block',
                          verticalAlign: 'middle',
                          textAlign: 'center',
                          lineHeight: '16px',
                          fontSize: '10px'
                        }}>
                        ?
                      </span>
                    </Tooltip>
                  </Text>
                  <Stack distribution="fillEvenly">
                    <div
                      style={{
                        fontSize: 'var(--p-font-size-24)',
                        fontWeight: 'var(--p-font-weight-bold)',
                        lineHeight: 'var(--p-line-height-7)',
                        fontFamily: 'var(--p-font-family-mono)',
                        marginTop: 'var(--p-space-3)'
                      }}>
                      {vendorOverview.active_list}
                    </div>
                  </Stack>
                </Card>
              </Grid.Cell>
            </Grid>
          ) : (
            <SkeletonBodyText lines={3} />
          )}
          <div style={{ marginTop: 'var(--p-space-4)' }}></div>
          {vendorList.map((vendor: vendorListDto, index) => (
            <LegacyCard>
              <LegacyCard.Header title={vendor.vendor}>
                {/*<Popover
                    active={vendorPopoverActive[index]}
                    onClose={() => togglePopoverActive(index)}
                    activator={
                      <Button
                        plain
                        onClick={() => togglePopoverActive(index)}
                        loading={vendorClickActive[index] || false}
                        disabled={vendorClickActive[index] || false}
                        icon={MobileHorizontalDotsMajor}></Button>
                    }>
                    <ActionList
                      actionRole="menuitem"
                      items={[
                        {
                          content: 'Remove Account',
                          onAction: () => vendorDeleteHandle(index, vendor._id)
                        },
                        {
                          content: `${vendor.status == 'inactive' ? 'Active' : 'InActive'} Account`,
                          onAction: () => vendorStatusHandle(index, vendor._id, vendor.status)
                        }
                      ]}
                    />
                  </Popover>*/}
                <Inline blockAlign="end">
                  <Button
                    plain
                    onClick={() => handleToggle(vendor._id, index, vendor.vendor)}
                    ariaControls="basic-collapsible"
                    icon={open[index] ? ChevronUpMinor : ChevronDownMinor}></Button>
                </Inline>
              </LegacyCard.Header>
              <Collapsible
                open={open[index] || false}
                id="basic-collapsible"
                transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
                expandOnPrint>
                <LegacyCard.Section>
                  <IndexTable
                    loading={vendorLoading[index]}
                    resourceName={resourceName}
                    itemCount={campaignList && campaignList[index] ? campaignList[index].length : 0}
                    headings={[
                      { title: 'Marketing List' },
                      { title: 'Email Count' },
                      {
                        id: 'order-count',
                        title: (
                          <Text as="span" variant="bodySm" fontWeight="medium" alignment="end">
                            Status
                          </Text>
                        )
                      },
                      {
                        id: 'amount-spent',
                        title: (
                          <Text as="span" variant="bodySm" fontWeight="medium" alignment="end">
                            Actions
                          </Text>
                        )
                      }
                    ]}
                    selectable={false}>
                    {rowMarkup(campaignList[index], index, vendor)}
                  </IndexTable>
                </LegacyCard.Section>
              </Collapsible>
              <Modal
                loading={confirmPopupLoader}
                open={confirmPopupActive}
                onClose={closeConfirmPopup}
                title={confirmPopupMessage}
                primaryAction={{
                  content: 'Yes',
                  disabled: confirmPopupLoader,
                  onAction: triggerModalEvent
                }}
                secondaryActions={[
                  {
                    content: 'No',
                    disabled: confirmPopupLoader,
                    onAction: closeConfirmPopup
                  }
                ]}></Modal>
            </LegacyCard>
          ))}
          {/*<div
            className={`videoThumbnail ${thumbnail ? 'show' : 'hidden'}`}
            style={{ marginTop: 'var(--p-space-4)' }}>
            <LegacyCard>
              <MediaCard
                title="Installation Guide"
                primaryAction={{
                  content: 'Learn more',
                  onAction: () => {
                    setThumbnail(!thumbnail);
                  }
                }}
                description={'Watch this video for complete setup & app functionality.'}>
                <VideoThumbnail
                  videoLength={80}
                  onClick={() => setThumbnail(!thumbnail)}
                  thumbnailUrl="https://burst.shopifycdn.com/photos/business-woman-smiling-in-office.jpg?width=1850"
                />
              </MediaCard>
            </LegacyCard>
          </div>
          <div
            className={`iframeVideo ${thumbnail ? 'hidden' : 'show'}`}
            style={{ marginTop: 'var(--p-space-4)' }}>
            <iframe src="https://www.youtube.com/embed/QqI5pMJuFbE" allow="autoplay"></iframe>
          </div>*/}
          <Box>
            <div style={{ marginTop: 'var(--p-space-12)', marginBottom: 'var(--p-space-8)' }}>
              <Text variant="headingMd" as="h1">
                Look For Help?
              </Text>
            </div>
            <Grid>
              <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}>
                <Text variant="headingSm" as="h2">
                  The App Compatible with Theme?
                </Text>
                <Text variant="bodyMd" as="p">
                  Our app is compatible with all the themes which have built-in newsletter form. If
                  you are facing any issues with the setup, feel free to{' '}
                  <Button
                    plain
                    onClick={() => {
                      window.open('https://salesparkapps.com/get-app-support/', '_blank');
                    }}>
                    contact support
                  </Button>
                  .
                </Text>
              </Grid.Cell>
              <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}>
                <Text variant="headingSm" as="h2">
                  Support Timings?
                </Text>
                <Text variant="bodyMd" as="p">
                  Our support is available through live chat or through email 24/7.
                </Text>
              </Grid.Cell>
              <Grid.Cell columnSpan={{ lg: 12, xl: 12 }}>
                <div style={{ marginTop: 'var(--p-space-5)' }}></div>
              </Grid.Cell>
              <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}>
                <Text variant="headingSm" as="h2">
                  Support Contact?
                </Text>
                <Text variant="bodyMd" as="p">
                  In case of any query feel free to submit your ticket on this email
                  support@salesparkapps.com.
                </Text>
              </Grid.Cell>
              <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}>
                <Text variant="headingSm" as="h2">
                  How to Uninstall?
                </Text>
                <Text variant="bodyMd" as="p">
                  Go to Admin &gt; Settings &gt; Apps and sales channels and then click on Uninstall
                  link to remove the app.
                </Text>
              </Grid.Cell>
              <Grid.Cell columnSpan={{ lg: 12, xl: 12 }}>
                <div style={{ marginTop: 'var(--p-space-8)' }}></div>
              </Grid.Cell>
            </Grid>
          </Box>
        </>
      )}
    </>
  );
};
