import { Navigate, Route, Routes as ReactRouterRoutes } from 'react-router-dom';
import React from 'react';
import Support from '../pages/authenticated/support';
import MailChimp from '../pages/authenticated/mail-chimp';
import { Dashboard } from '../pages/authenticated/dashboard/dashboard';
import { Welcome } from '../pages/authenticated/welcome';
import { AddVendor } from '../pages/authenticated/vendor/add';
import { Plan } from '../pages/authenticated/plan';
import AppLayout from '../components/layout/layout';
import ErrorElement from '../components/error/error';

const Routes: React.FC = () => {
  return (
    <ReactRouterRoutes>
      <Route path="/" element={<AppLayout />} errorElement={<ErrorElement />}>
        <Route path="/" element={<Navigate to="/dashboard" />} />
        <Route path="/welcome" element={<Welcome />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/vendor/add" element={<AddVendor />} />
        <Route path="/plan" element={<Plan />} />
        <Route path="/support" element={<Support />} />
        <Route path="/mail-chimp" element={<MailChimp />} />
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Route>
    </ReactRouterRoutes>
  );
};
export default Routes;
