import React, { useCallback, useEffect } from 'react';
import { Banner } from '@shopify/polaris';
import { useBannersState } from '../../states/useBanners';
import { notify, shopifyBanner } from '../../dto/types';
import { BannerStatus } from '@shopify/polaris/build/ts/latest/src/components/Banner/Banner';
import { useRequest } from '../../hooks/useRequest';
import { apiRoutes } from '../../utils/api.routes';
import { ResponseDto } from '../../dto/response';
import { useNavigate } from 'react-router-dom';

export const Banners: React.FC = () => {
  const { banners, addBanner, removeBanner } = useBannersState();
  const { get } = useRequest();
  const navigate = useNavigate();

  const checkNotification = useCallback(() => {
    /*get(apiRoutes.notifyCheck).then((response: ResponseDto) => {
      if (response.data.notify) {
        response.data.notify.map((x: notify) => {
          let setupBanner: shopifyBanner | null = {
            title: x.title,
            message: x.message,
            status: x.notifyStatus,
            notifyType: x.notifyType
          };
          addBanner(setupBanner);
        });
      }
    });*/
  }, [addBanner, get]);

  useEffect(() => {
    checkNotification();
  }, [checkNotification]);

  return (
    <>
      {banners.map((banner: shopifyBanner) => (
        <Banner
          key={banner.id}
          title={banner.title}
          status={banner.status as BannerStatus}
          action={
            banner.primaryActionContent
              ? {
                  content: banner.primaryActionContent,
                  onAction: () =>
                    banner.primaryActionCallBack ? banner.primaryActionCallBack() : {}
                }
              : undefined
          }
          secondaryAction={{
            content: banner.secondaryActionContent ?? undefined,
            onAction: () => (banner.secondaryActionCallBack ? banner.secondaryActionCallBack() : {})
          }}
          onDismiss={() =>
            banner.onDismiss ? banner.onDismiss(banner.id ?? '') : removeBanner(banner.id ?? '')
          }>
          <p>{banner.message}</p>
        </Banner>
      ))}
    </>
  );
};
