import React, { useEffect, useRef } from 'react';
import {
  Box,
  Text,
  Grid,
  EmptyState,
  LegacyCard,
  SkeletonPage,
  TextContainer,
  SkeletonDisplayText,
  SkeletonBodyText,
  Button
} from '@shopify/polaris';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRequest } from '../../../hooks/useRequest';
import { apiRoutes } from '../../../utils/api.routes';
import statusCodes from '../../../utils/status.codes';
import { ResponseDto } from '../../../dto/response';

export const Welcome: React.FC = () => {
  const navigate = useNavigate();

  //const [trialDays, setTrialDays] = useState(0);
  //const [trialPercent, setTrialPercent] = useState(0);
  const [pageLoading, setPageLoading] = useState(true);
  const { get, post } = useRequest();
  let oneRequest = true;

  useEffect(() => {
    get(apiRoutes.authCheck).then((response: ResponseDto) => {
      if (response.status == statusCodes.SUCCESS) {
        if (response.data.data.onBoarding) {
          navigate('/dashboard', {
            state: location.pathname
          });
        } else {
          setPageLoading(false);
          trackEvent();
        }
      }
    });

    get(apiRoutes.subscriptionTrial).then((response: ResponseDto) => {
      if (response.status == statusCodes.SUCCESS) {
        //setTrialDays(response.data.data.trialDays);
        //setTrialPercent(response.data.data.percentage);
      }
    });
  }, []);

  const trackEvent = () => {
    if (oneRequest) {
      post(apiRoutes.trackMixPanel, { event: 'Welcome Page' }).then((response: ResponseDto) => {});
      oneRequest = false;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {pageLoading ? (
        <SkeletonPage>
          <LegacyCard sectioned>
            <SkeletonBodyText />
          </LegacyCard>
          <LegacyCard>
            <LegacyCard.Section>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={2} />
              </TextContainer>
            </LegacyCard.Section>
            <LegacyCard.Section>
              <SkeletonBodyText lines={1} />
            </LegacyCard.Section>
          </LegacyCard>
          <LegacyCard subdued>
            <LegacyCard.Section>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={2} />
              </TextContainer>
            </LegacyCard.Section>
            <LegacyCard.Section>
              <SkeletonBodyText lines={2} />
            </LegacyCard.Section>
          </LegacyCard>
        </SkeletonPage>
      ) : (
        <>
          <LegacyCard>
            <EmptyState
              heading="Connect and Sync Newsletter Forms!"
              action={{
                content: 'Sync your first subscriber',
                onAction: () => {
                  navigate('/vendor/add', {
                    state: location.pathname
                  });
                }
              }}
              secondaryAction={{
                content: 'Setup Guide',
                onAction: () => {
                  window.open('https://salespark-apps-support.tawk.help/', '_blank');
                }
              }}
              image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png">
              <p>
                Connect and Sync Your Shopify Newsletter with Top Email Marketing Services -
                Mailchimp, SendGrid.
              </p>
            </EmptyState>
          </LegacyCard>
          {/*<div className={'iframeVideo }'} style={{ marginTop: 'var(--p-space-4)' }}>
            <iframe src="https://www.youtube.com/embed/QqI5pMJuFbE" allow="autoplay"></iframe>
          </div>
          <div ref={targetRef}></div>*/}
          <Box>
            <div
              id={'faqSection'}
              style={{ marginTop: 'var(--p-space-12)', marginBottom: 'var(--p-space-8)' }}>
              <Text variant="headingMd" as="h1">
                Look For Help?
              </Text>
            </div>
            <Grid>
              <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}>
                <Text variant="headingSm" as="h2">
                  The App Compatible with Theme?
                </Text>
                <Text variant="bodyMd" as="p">
                  Our app is compatible with all the themes which have built-in newsletter form. If
                  you are facing any issues with the setup, feel free to{' '}
                  <Button
                    plain
                    onClick={() => {
                      window.open('https://salesparkapps.com/get-app-support/', '_blank');
                    }}>
                    contact support
                  </Button>
                  .
                </Text>
              </Grid.Cell>
              <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}>
                <Text variant="headingSm" as="h2">
                  Support Timings?
                </Text>
                <Text variant="bodyMd" as="p">
                  Our support is available through live chat or through email 24/7.
                </Text>
              </Grid.Cell>
              <Grid.Cell columnSpan={{ lg: 12, xl: 12 }}>
                <div style={{ marginTop: 'var(--p-space-5)' }}></div>
              </Grid.Cell>
              <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}>
                <Text variant="headingSm" as="h2">
                  Support Contact?
                </Text>
                <Text variant="bodyMd" as="p">
                  In case of any query feel free to submit your ticket on this email
                  support@salesparkapps.com.
                </Text>
              </Grid.Cell>
              <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}>
                <Text variant="headingSm" as="h2">
                  How to Uninstall?
                </Text>
                <Text variant="bodyMd" as="p">
                  Go to Admin &gt; Settings &gt; Apps and sales channels and then click on Uninstall
                  link to remove the app.
                </Text>
              </Grid.Cell>
              <Grid.Cell columnSpan={{ lg: 12, xl: 12 }}>
                <div style={{ marginTop: 'var(--p-space-8)' }}></div>
              </Grid.Cell>
            </Grid>
          </Box>
        </>
      )}
    </>
  );
};
