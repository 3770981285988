import create from 'zustand';
import { v4 as uuidv4 } from 'uuid';
import { shopifyBanner } from '../dto/types';

type bannerState = {
  banners: shopifyBanner[];
  addBanner: (banner: shopifyBanner) => void;
  removeBanner: (id: string) => void;
};

export const useBannersState = create<bannerState>((set, get) => ({
  banners: [],
  addBanner: (banner: shopifyBanner) => {
    const oldBanners = get().banners;
    const findBanner = oldBanners.find((x) => x.notifyType === banner.notifyType);
    if (findBanner === undefined) {
      banner.id = uuidv4();
      set((state) => ({
        banners: [...state.banners, banner]
      }));
    }
  },
  removeBanner: (id: string) => {
    set((state) => ({
      banners: state.banners.filter((banner) => banner.id !== id)
    }));
  }
}));
