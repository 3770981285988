import React, { useState } from 'react';
import { Button, FormLayout, Grid, Modal, Text, TextField } from '@shopify/polaris';
import { apiRoutes } from '../../utils/api.routes';
import { useRequest } from '../../hooks/useRequest';
import { FeedBackCreate } from '../../dto/interfaces';
import statusCodes from '../../utils/status.codes';
import { ResponseDto } from '../../dto/response';

export const FeedBack: React.FC = () => {
  const [showFeedBack, setShowFeedBack] = useState(false);
  const [isRequestOpen, setIsRequestOpen] = useState(false);

  const initFeedBackRequest: FeedBackCreate = {
    title: '',
    description: ''
  };
  const [feedbackRequest, setFeedbackRequest] = useState<FeedBackCreate>(initFeedBackRequest);
  const { post } = useRequest();

  const handleChange = (value: string, key: string) => {
    feedbackRequest[key] = value;
    setFeedbackRequest({ ...feedbackRequest });
  };

  const submitFeedBack = () => {
    setIsRequestOpen(true);
    post(apiRoutes.createFeedBack, feedbackRequest).then((response: ResponseDto) => {
      if (response.data.statusCode === statusCodes.SUCCESS) {
        setShowFeedBack(false);
        setFeedbackRequest({ ...initFeedBackRequest });
      }
      setIsRequestOpen(false);
    });
  };

  return (
    <Modal small open={showFeedBack} onClose={() => setShowFeedBack((active) => !active)} title="">
      <Grid>
        <Grid.Cell columnSpan={{ lg: 12, xl: 12 }}>
          <Text variant="headingMd" as="h2">
            <div
              style={{
                borderBottom: 'var(--p-border-divider)',
                padding: 'var(--p-space-4) var(--p-space-5)'
              }}>
              We'd love your feedback
              <p
                style={{
                  margin: '0',
                  fontSize: 'var(--p-font-size-75)',
                  lineHeight: 'var(--p-font-line-height-1)'
                }}>
                We listen to feedback from out app user's and do our best to implement highly
                suggested request's.
              </p>
            </div>
          </Text>
        </Grid.Cell>
      </Grid>
      <div
        style={{
          padding: 'var(--p-space-6)',
          background: 'var(--p-surface-neutral-disabled)'
        }}>
        <Grid>
          <Grid.Cell columnSpan={{ lg: 12, xl: 12 }}>
            <FormLayout>
              <TextField
                label=""
                placeholder="Title"
                value={feedbackRequest.title}
                onChange={(value: string) => handleChange(value, 'title')}
                autoComplete="off"
              />
              <TextField
                label=""
                multiline={4}
                placeholder="Description"
                value={feedbackRequest.description}
                onChange={(value: string) => handleChange(value, 'description')}
                autoComplete="off"
              />
            </FormLayout>
          </Grid.Cell>
        </Grid>
      </div>
      <div
        style={{
          padding: 'var(--p-space-6)'
        }}>
        <Grid>
          <Grid.Cell columnSpan={{ xs: 6, lg: 6 }}>
            <div
              style={{
                marginTop: '0.55rem'
              }}>
              <Button fullWidth plain onClick={() => setShowFeedBack(false)}>
                No Thanks!
              </Button>
            </div>
          </Grid.Cell>
          <Grid.Cell columnSpan={{ lg: 6 }}>
            <Button fullWidth primary onClick={submitFeedBack} disabled={isRequestOpen}>
              Submit Feedback
            </Button>
          </Grid.Cell>
        </Grid>
      </div>
    </Modal>
  );
};
