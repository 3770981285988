import create from 'zustand';
import { v4 as uuidv4 } from 'uuid';
import { shopifyToast } from '../dto/types';

type toastState = {
  toasts: shopifyToast;
  setToast: (toast: shopifyToast) => void;
  removeToast: (id: string) => void;
};
let error403 = false;
export const useToasts = create<toastState>((set) => ({
  toasts: { message: '', isError: false },
  setToast: (toast: shopifyToast) => {
    toast.id = uuidv4();
    if (!error403) {
      set((state) => ({
        toasts: toast
      }));
    }
    if (toast.status == 403) {
      error403 = true;
    }
  },
  removeToast: (id: string) => {
    set((state) => ({
      toasts: { message: '', isError: false }
    }));
  }
}));
