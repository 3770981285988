import create from 'zustand';

type loaderState = {
  showLoader: boolean;
  setLoader: (loaderState: boolean) => void;
};

export const useLoader = create<loaderState>((set) => ({
  showLoader: false,
  setLoader: (loaderState: boolean) => {
    set(() => ({
      showLoader: loaderState
    }));
  }
}));
