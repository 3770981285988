import React, { useEffect, useState } from 'react';
import { Toast, Frame } from '@shopify/polaris';
import { useToasts } from '../states/useToast';
import { shopifyToast } from '../dto/types';

const LayoutToast: React.FC = () => {
  const { toasts, removeToast } = useToasts();

  return (
    <>
      {toasts.message != '' && (
        <Toast
          key={toasts.id}
          content={toasts.message}
          error={toasts.isError}
          onDismiss={() => removeToast(toasts.id ?? '')}
        />
      )}
    </>
  );
};

export default LayoutToast;
