import React, { useCallback, useState } from 'react';
import { Text, Tabs, Layout, AlphaCard, CalloutCard, LegacyCard } from '@shopify/polaris';

const Support: React.FC = () => {
  const [selected, setSelected] = useState(0);
  const handleTabChange = useCallback(
    (selectedTabIndex: number) => setSelected(selectedTabIndex),
    []
  );

  const redirectOutSide = (link: string) => {
    window.open(link, '_blank');
  };

  const tabs = [
    {
      id: 'support',
      content: 'Support',
      children: (
        <div className="supportBox">
          <CalloutCard
            title="View Knowledge Base"
            illustration="https://cdn.shopify.com/s/assets/admin/checkout/settings-customizecart-705f57c725ac05be5a34ec20c05b94298cb8afd10aac7bd9c7ad02030f48cfa0.svg"
            primaryAction={{
              content: 'Knowledge Base',
              onAction: () => redirectOutSide('https://salespark-apps-support.tawk.help/')
            }}>
            <p>Visit our knowledge base for Spark Newsletter Email Sync setup and FAQs.</p>
          </CalloutCard>
          <div style={{ marginTop: 'var(--p-space-4)' }}></div>
          <CalloutCard
            title="Get App Support"
            illustration="https://cdn.shopify.com/s/assets/admin/checkout/settings-customizecart-705f57c725ac05be5a34ec20c05b94298cb8afd10aac7bd9c7ad02030f48cfa0.svg"
            primaryAction={{
              content: 'Send Message',
              onAction: () => redirectOutSide('https://salesparkapps.com/get-app-support/')
            }}>
            <p>
              Having trouble with Spark Newsletter Email Sync? Contact our customer support for
              assistance with syncing, connectivity, and data transfer between your Shopify store
              and Email marketing vendor.
            </p>
          </CalloutCard>
          <div style={{ marginTop: 'var(--p-space-4)' }}></div>
          <CalloutCard
            title="Request A Feature"
            illustration="https://cdn.shopify.com/s/assets/admin/checkout/settings-customizecart-705f57c725ac05be5a34ec20c05b94298cb8afd10aac7bd9c7ad02030f48cfa0.svg"
            primaryAction={{
              content: 'Request',
              onAction: () => redirectOutSide('https://salesparkapps.com/request-a-feature')
            }}>
            <p>
              We are always looking to improve our product. Would you like us to add any new
              features? If so, which features would you like to see?
            </p>
          </CalloutCard>
          <div style={{ marginTop: 'var(--p-space-4)' }}></div>
        </div>
      ),
      panelID: 'repeat-customers-content-1'
    },
    {
      id: 'changelog',
      content: 'Changelog',
      children: (
        <div className="changeLog">
          <AlphaCard>
            <Layout.AnnotatedSection title="v1" description="04-15-2023">
              <Text as="h2" variant="bodyMd">
                Initial release
              </Text>
            </Layout.AnnotatedSection>
          </AlphaCard>
        </div>
      ),
      panelID: 'accepts-marketing-content-1'
    }
  ];

  return (
    <>
      <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
        <LegacyCard.Section>{tabs[selected].children}</LegacyCard.Section>
      </Tabs>
    </>
  );
};

export default Support;
