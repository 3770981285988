import { useAxios } from './useAxios';

export type contentType = { urlencoded: string; multipart: string; json: string };

export const useRequest = (isNotify = true) => {
  const axiosInstance = useAxios(isNotify);

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const post = (path: string, data: any, header = 'json') => {
    header = contentType(header);
    return axiosInstance.post(path, data, {
      headers: {
        'Content-Type': header
      }
    });
  };

  const get = (path: string, params = {}) => {
    return axiosInstance.get(path, { params: params });
  };

  const destroy = (path: string, data: any, params = {}) => {
    return axiosInstance.delete(path, {
      data: data,
      params: params
    });
  };

  const put = (path: string, data: any, header = 'json') => {
    header = contentType(header);
    return axiosInstance.put(path, data, {
      headers: {
        'Content-Type': header
      }
    });
  };

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const patch = (path: string, data: any, params = {}, header = 'json') => {
    header = contentType(header);
    return axiosInstance.patch(path, data, {
      params: params,
      headers: {
        'Content-Type': header
      }
    });
  };

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const upload = (path: string, data: any, config = {}) => {
    return axiosInstance.patch(path, data, config);
  };

  const contentType = (header: string): string => {
    const headers: contentType = {
      json: 'application/json',
      multipart: 'multipart/form-data',
      urlencoded: 'application/x-www-form-urlencoded'
    };
    return headers[header as keyof contentType];
  };

  return {
    get,
    post,
    patch,
    upload,
    destroy,
    put
  };
};
