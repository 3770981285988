import React, { useCallback, useEffect, useRef } from 'react';
import {
  Icon,
  Text,
  Link,
  List,
  Inline,
  Button,
  Banner,
  Select,
  Columns,
  Checkbox,
  TextField,
  AlphaCard,
  LegacyCard,
  AlphaStack,
  FormLayout,
  SkeletonPage,
  SkeletonBodyText,
  Modal
} from '@shopify/polaris';
import { ExternalMinor, CircleTickMajor, MobileChevronMajor } from '@shopify/polaris-icons';
import { useState } from 'react';
import { apiRoutes } from '../../../utils/api.routes';
import { ResponseDto } from '../../../dto/response';
import statusCodes from '../../../utils/status.codes';
import { useRequest } from '../../../hooks/useRequest';
import { bannerDto, optionsDto, TargetElementType } from '../../../dto/types';
import { useNavigate } from 'react-router-dom';
import { useToasts } from '../../../states/useToast';

export const AddVendor: React.FC = () => {
  const [active, setActive] = useState(false);
  const handleChange = useCallback(() => setActive(!active), [active]);
  const activator = (
    <Link onClick={handleChange} url="/settings">
      Want to learn how?
    </Link>
  );

  const [submitLoader, setSubmitLoader] = useState(false);
  const [removePopup, setRemovePopup] = useState(false);
  const [removeLoader, setRemoveLoader] = useState(false);
  const [sendGridSync, setSendGridSync] = useState(false);
  const [vendorLoading, setVendorLoading] = useState(true);
  const [vendorMailChimpLoading, setVendorMailChimpLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [mailchimpSync, setMailchimpSync] = useState(false);
  const [vendorTab, SetVendorTab] = useState('');
  const [vendorID, setVendorID] = useState('');
  const [sendgridApi, setSendgridApi] = useState('');
  const [audienceList, setAudienceList] = useState<Array<optionsDto>>([]);
  const [contactList, setContactList] = useState<Array<optionsDto>>([]);
  const [selectContact, setSelectContact] = useState<optionsDto>({
    label: undefined,
    value: undefined
  });
  const [selectAudience, setSelectAudience] = useState<optionsDto>({
    label: undefined,
    value: undefined
  });
  const [tagsList, setTagsList] = useState<Array<Array<optionsDto>>>([]);
  const [vendorApi, setVendorApi] = useState('');
  const [selectTag, setSelectTag] = useState('');
  const [tagsOptions, setTagsOptions] = useState<any>([]);
  const { get, post, destroy, patch } = useRequest();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const vendor: string | null = queryParams.get('vendor');
  let oneTimeRequest = true;
  const [alertBanner, setAlertBanner] = useState<bannerDto>({
    status: false,
    message: ''
  });
  const { setToast } = useToasts();
  const scrollDownRef = useRef<TargetElementType>(null);

  const changeVendorTab = (type: string) => {
    if (vendorTab == type) {
      return;
    }
    resetStates();
    SetVendorTab(type);
    setLoading(true);
    get(apiRoutes.checkVendor + `/${type}`)
      .then((response: ResponseDto) => {
        if (response.data.data != null) {
          if (type == 'mailchimp') {
            fetchMailChimpAudienceList(response.data.data._id);
            setVendorApi(response.data.data.accessToken);
          }
          if (type == 'sendGrid') {
            fetchSendGridList(response.data.data._id);
            setVendorApi(response.data.data.apiKey);
          }
          setVendorID(response.data.data._id);
          setLoading(false);
        } else {
          setVendorID('');
          setLoading(false);
        }
      })
      .catch((error) => {
        if (error?.response != undefined && error.response.status === 422) {
          setVendorID('');
          setLoading(false);
        }
      });
  };

  const resetStates = () => {
    setSubmitLoader(false);
    setVendorID('');
    SetVendorTab('');
    setAudienceList([]);
    setTagsList([]);
    setAlertBanner({
      status: false,
      message: ''
    });
  };

  const removeVendor = () => {
    setRemoveLoader(true);
    destroy(apiRoutes.vendorDelete, { vendorId: vendorID }).then((response: ResponseDto) => {
      if (response.status == statusCodes.SUCCESS) {
        setSendgridApi('');
        setVendorID('');
        setRemoveLoader(false);
        setRemovePopup(false);
      }
    });
  };

  useEffect(() => {
    if (oneTimeRequest) {
      trackEvent('Vendor Page');
      if (vendor != undefined && vendor == 'mailchimp') {
        changeVendorTab('mailchimp');
      } else {
        changeVendorTab('sendGrid');
      }
    }
    oneTimeRequest = false;
  }, [vendor]);

  const trackEvent = (event: string) => {
    post(apiRoutes.trackMixPanel, { event: event }).then((response: ResponseDto) => {});
  };

  const fetchMailChimpAudienceList = (vendorID: string) => {
    setVendorMailChimpLoading(true);
    get(apiRoutes.mailChimpLiveList + `/${vendorID}`).then((response: ResponseDto) => {
      if (response.status == statusCodes.SUCCESS) {
        const audience = response.data.data.list;
        const audienceArray = [];
        if (audience.length > 0) {
          const tags = [...tagsList];
          audienceArray.push({ label: 'Select Audience', value: 0 });
          tags[0] = [];
          for (let i = 0; i < audience.length; i++) {
            const obj = { label: audience[i].name, value: audience[i].id };
            audienceArray.push(obj);
            const liveTags = audience[i].tags;
            if (liveTags.length > 0) {
              const tagsArray = [];
              tagsArray.push({ label: 'select Tag', value: '' });
              for (let j = 0; j < liveTags.length; j++) {
                const obj = { label: liveTags[j].name, value: liveTags[j].name };
                tagsArray.push(obj);
              }
              tags[audience[i].id] = tagsArray;
            } else {
              tags[audience[i].id] = [];
            }
          }
          if (response.data.data.select_list != null) {
            setSelectAudience({
              label: response.data.data.select_list.company,
              value: response.data.data.select_list.list
            });
            setSelectTag(response.data.data.select_list.tag);
          } else {
            setSelectAudience({
              label: audienceArray[0].label,
              value: audienceArray[0].value
            });
            setAlertBanner({
              status: true,
              message: 'MailChimp'
            });
          }
          setTagsList(tags);
          setAudienceList(audienceArray);
        }
      }
      setVendorMailChimpLoading(false);
    });
  };

  const changeTagOption = (listId: any) => {
    if (tagsList[listId] != undefined) {
      setTagsOptions(tagsList[listId]);
    } else {
      setTagsOptions(tagsList[audienceList[0].value]);
    }
  };

  useEffect(() => {
    if (audienceList.length > 0) {
      if (selectAudience.value == undefined) {
        changeTagOption(audienceList[0].value);
      } else {
        changeTagOption(selectAudience.value);
      }
    }
  }, [audienceList]);

  useEffect(() => {
    if (tagsOptions != undefined && tagsOptions.length > 0) {
      if (selectTag == '') {
        setSelectTag(tagsOptions[0].value);
      }
    } else {
      setSelectTag('');
    }
  }, [tagsOptions]);

  const mailChimpAuth = () => {
    setSubmitLoader(true);
    get(apiRoutes.mailChimpCallBack).then((response: ResponseDto) => {
      if (response.status == statusCodes.SUCCESS) {
        const data = response.data.data;
        setSubmitLoader(false);
        window.parent.location.href = data.url;
      }
    });
  };

  const handleAudienceChange = (value: string) => {
    const name = audienceList.find((item) => item.value === value);
    setSelectAudience({
      label: name?.label,
      value: value
    });
    changeTagOption(value);
  };

  const handleTagChange = (value: string) => {
    setSelectTag(value);
  };

  const sendGridApiHandler = (value: string) => {
    value = value.replace(/\s/g, '');
    setSendgridApi(value);
  };

  const handleContactChange = (value: string) => {
    const name = contactList.find((item) => item.value === value);
    setSelectContact({
      label: name?.label,
      value: value
    });
  };

  const saveSendGridAccount = () => {
    if (sendgridApi != '') {
      setSubmitLoader(true);
      post(apiRoutes.sendGridConnect, { apikey: sendgridApi }).then((response: ResponseDto) => {
        if (response.data.statusCode == statusCodes.SUCCESS) {
          setVendorID(response.data.data.vendorId);
          SetVendorTab('sendGrid');
          fetchSendGridList(response.data.data.vendorId);
          setVendorApi(response.data.data.apiKey);
        } else {
          setSendgridApi('');
        }
        setSubmitLoader(false);
      });
    } else {
      setToast({
        message: 'Empty Api Key Field',
        isError: true
      });
    }
  };

  const fetchSendGridList = (vendorID: string) => {
    setVendorLoading(true);
    let alert = false;
    get(apiRoutes.sendGridLiveList + `/${vendorID}`).then((response: ResponseDto) => {
      const list = response.data.data.list.result;
      if (list.length > 0) {
        const contacts = [];
        contacts.push({ label: 'Select Contact List', value: '' });
        for (let i = 0; i < list.length; i++) {
          const obj = { label: list[i].name, value: list[i].id };
          contacts.push(obj);
        }
        setContactList(contacts);
        if (response.data.data.select_list != null) {
          setSelectContact({
            label: response.data.data.select_list.company,
            value: response.data.data.select_list.list
          });
        } else {
          setSelectContact({
            label: contacts[0].label,
            value: contacts[0].value
          });
          alert = true;
        }
      } else {
        alert = true;
      }

      if (alert) {
        setAlertBanner({
          status: true,
          message: 'SendGrid'
        });
      }
      setVendorLoading(false);
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const createMailChimpCampaign = () => {
    if (selectAudience.value != 0) {
      setSubmitLoader(true);
      const data = {
        vendorId: vendorID,
        campaignId: selectAudience.value,
        company: selectAudience.label,
        tag: selectTag,
        customerSync: mailchimpSync
      };
      patch(apiRoutes.sendMailchimpSave, data).then((response: ResponseDto) => {
        if (response.status == statusCodes.SUCCESS) {
          setSubmitLoader(false);
          navigate('/dashboard', {
            state: location.pathname
          });
        }
      });
    } else {
      setToast({
        message: 'Select Audience From DropDown',
        isError: true
      });
    }
  };

  const createSendGridCampaign = () => {
    setSubmitLoader(true);
    let company = selectContact.label;
    if (selectContact.value == '') {
      company = 'All Contacts';
    }
    const data = {
      vendorId: vendorID,
      campaignId: selectContact.value,
      company: company,
      customerSync: sendGridSync
    };
    patch(apiRoutes.sendGridCampaignSave, data).then((response: ResponseDto) => {
      if (response.status == statusCodes.SUCCESS) {
        setSubmitLoader(false);
        navigate('/dashboard', {
          state: location.pathname
        });
      }
    });
  };

  const closeRemovePopup = () => {
    setRemovePopup(false);
  };

  const handleSettingsLinkNewTab = (link: string) => {
    window.open(link, '_blank'); // Opens the URL in a new tab
  };

  const scrollToElement = () => {
    if (scrollDownRef.current) {
      scrollDownRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <div className="topLogBox" style={{ marginBottom: 'var(--p-space-4)' }}>
        <Button
          icon={MobileChevronMajor}
          onClick={() => {
            navigate('/dashboard', { state: location.pathname });
          }}>
          Back to home
        </Button>
        <div style={{ marginBottom: 'var(--p-space-4)' }}></div>
        <Columns gap={{ xs: '2' }} columns={{ xs: '1fr 1fr 1fr' }}>
          <AlphaCard padding={{ xs: '2', sm: '3', md: '4', lg: '5', xl: '6' }}>
            <div
              className={vendorTab == 'sendGrid' ? 'imagewrape active' : 'imagewrape'}
              onClick={() => changeVendorTab('sendGrid')}>
              <Icon source={CircleTickMajor} color="success" />
            </div>
            <AlphaStack align="center">
              <img
                width="150"
                height="75"
                src="https://www.vectorlogo.zone/logos/sendgrid/sendgrid-ar21.svg"
                alt="Send Grid"
              />
            </AlphaStack>
          </AlphaCard>
          <AlphaCard padding={{ xs: '2', sm: '3', md: '4', lg: '5', xl: '6' }}>
            <div
              className={vendorTab == 'mailchimp' ? 'imagewrape active' : 'imagewrape'}
              onClick={() => changeVendorTab('mailchimp')}>
              <Icon source={CircleTickMajor} color="success" />
            </div>
            <AlphaStack align="center">
              <img
                width="150"
                height="45"
                src="https://www.underconsideration.com/brandnew/archives/mailchimp_2018_logo.png"
                alt="Mailchimp"
              />
            </AlphaStack>
          </AlphaCard>
        </Columns>
      </div>
      <div style={{ marginBottom: 'var(--p-space-4)' }}></div>
      {!loading ? (
        <>
          {vendorID == '' && (
            <>
              {vendorTab == 'sendGrid' && (
                <LegacyCard sectioned title="SendGrid">
                  <TextField
                    label="Api secret key"
                    type="text"
                    helpText="The integration provides you with an ability to save customers contacts from your
                    Shopify store to SendGrid."
                    placeholder="Enter your api secret key here"
                    autoComplete=""
                    onChange={sendGridApiHandler}
                    value={sendgridApi}
                  />
                  <div style={{ paddingBottom: 'var(--p-space-2)' }}></div>
                  <Text fontWeight="regular" variant="bodyMd" as="p">
                    You'll need to get API secret key from your{' '}
                    <Link
                      onClick={() =>
                        handleSettingsLinkNewTab('https://app.sendgrid.com/settings/api_keys')
                      }>
                      SendGrid account
                    </Link>{' '}
                    to setup integration.
                  </Text>
                  <Modal
                    activator={activator}
                    open={active}
                    onClose={handleChange}
                    title="Steps to Generate a SendGrid API Key">
                    <Modal.Section>
                      <List type="number">
                        <List.Item>Log in to your SendGrid Account.</List.Item>
                        <List.Item>Navigate to Settings&gt;API Keys</List.Item>
                        <List.Item>Click the "Create API Key" button.</List.Item>
                        <List.Item>
                          Give your API Key a name and choose the permissions you want to grant to
                          the key.
                        </List.Item>
                        <List.Item>Click the "Create & View" button.</List.Item>
                        <List.Item>
                          Copy your new API key and enter into API secret key text field.
                        </List.Item>
                      </List>
                    </Modal.Section>
                  </Modal>
                  <div style={{ paddingBottom: 'var(--p-space-4)' }}></div>
                  <Button
                    primary
                    onClick={saveSendGridAccount}
                    loading={submitLoader}
                    disabled={submitLoader}>
                    Connect account
                  </Button>
                </LegacyCard>
              )}
              {vendorTab == 'mailchimp' && (
                <LegacyCard sectioned title="Mailchimp">
                  <Text variant="bodyMd" as="p">
                    The integration provides you with an ability to save customers contacts from
                    Shopify store to Mailchimp.
                  </Text>
                  <div style={{ marginBottom: 'var(--p-space-4)' }}></div>
                  <Inline gap="1">
                    <div>
                      <Icon source={ExternalMinor} color="base" />
                    </div>{' '}
                    <Link
                      onClick={() =>
                        handleSettingsLinkNewTab('https://mailchimp.com/help/connect-shopify/')
                      }>
                      Want to learn how?
                    </Link>
                  </Inline>
                  <div style={{ marginBottom: 'var(--p-space-4)' }}></div>
                  <Button
                    primary
                    onClick={mailChimpAuth}
                    loading={submitLoader}
                    disabled={submitLoader}>
                    Connect account
                  </Button>
                </LegacyCard>
              )}
            </>
          )}
        </>
      ) : (
        <SkeletonPage>
          <LegacyCard sectioned>
            <SkeletonBodyText />
          </LegacyCard>
        </SkeletonPage>
      )}
      {vendorID != '' && (
        <>
          {alertBanner.status && (
            <>
              <Banner status="warning">
                <p>
                  <strong>Congratulations</strong> on connecting to {alertBanner.message}! <br />{' '}
                  Just one more step to start email sync:{' '}
                  <Button onClick={scrollToElement} plain monochrome>
                    click here
                  </Button>{' '}
                  to select a campaign list from the dropdown and save.
                </p>
              </Banner>
              <div style={{ marginTop: 'var(--p-space-4)' }}></div>
            </>
          )}
          {vendorTab == 'mailchimp' ? (
            <>
              <LegacyCard sectioned title="MailChimp token">
                <TextField label="" type="text" disabled={true} value={vendorApi} autoComplete="" />
                <p>
                  You are currently utilizing the above token to synchronize Shopify customers with
                  MailChimp.{' '}
                  <Button plain monochrome onClick={() => setRemovePopup(true)}>
                    Disconnect
                  </Button>
                </p>
              </LegacyCard>
              <LegacyCard>
                <LegacyCard.Section>
                  {!vendorMailChimpLoading ? (
                    <FormLayout>
                      {audienceList.length > 0 ? (
                        <>
                          <Select
                            label="Default audience*"
                            helpText="Please select the Mailchimp audience that you would like to synchronize
                            with your newsletter subscribers."
                            options={audienceList}
                            value={selectAudience.value}
                            onChange={handleAudienceChange}
                          />
                          <div style={{ paddingTop: 'var(--p-space-2)' }} ref={scrollDownRef}></div>
                          {tagsOptions.length > 0 && (
                            <div>
                              <Select
                                label="Customer tag"
                                helpText="Please select the customer tag that you would like to synchronize your
                              customers with."
                                options={tagsOptions}
                                value={selectTag}
                                onChange={handleTagChange}
                              />
                              <div style={{ paddingTop: 'var(--p-space-2)' }}></div>
                            </div>
                          )}
                          <Checkbox
                            label="Synchronize your existing/previous subscribers"
                            checked={mailchimpSync}
                            onChange={(e) => setMailchimpSync(e)}
                          />
                          <div style={{ paddingTop: 'var(--p-space-3)' }}></div>
                          <Button
                            primary
                            onClick={createMailChimpCampaign}
                            loading={submitLoader}
                            disabled={submitLoader}>
                            Save selection
                          </Button>
                        </>
                      ) : (
                        <p>
                          No MailChimp audience list found{' '}
                          <Link
                            onClick={() =>
                              handleSettingsLinkNewTab(
                                'https://mailchimp.com/help/create-new-audience-group/'
                              )
                            }
                            external
                            monochrome>
                            Click Here
                          </Link>{' '}
                          to create list
                        </p>
                      )}
                    </FormLayout>
                  ) : (
                    <SkeletonPage>
                      <LegacyCard sectioned>
                        <SkeletonBodyText />
                      </LegacyCard>
                    </SkeletonPage>
                  )}
                </LegacyCard.Section>
              </LegacyCard>
            </>
          ) : (
            <>
              <LegacyCard sectioned title="SendGrid api key">
                <TextField label="" type="text" disabled={true} value={vendorApi} autoComplete="" />
                <div style={{ paddingTop: 'var(--p-space-2)' }}></div>
                <p>
                  You are currently utilizing the above key to synchronize Shopify customers with
                  Sendgrid.{' '}
                  <Button plain monochrome onClick={() => setRemovePopup(true)}>
                    Disconnect
                  </Button>
                </p>
              </LegacyCard>
              <LegacyCard>
                <LegacyCard.Section>
                  {!vendorLoading ? (
                    <FormLayout>
                      {contactList.length > 0 ? (
                        <>
                          <Select
                            label="Select list*"
                            options={contactList}
                            value={selectContact.value}
                            helpText="Please select the list that you would like to synchronize with your
                              newsletter subscribers."
                            onChange={handleContactChange}
                          />
                          <div style={{ paddingTop: 'var(--p-space-2)' }} ref={scrollDownRef}></div>
                          <Checkbox
                            label="Synchronize your existing/previous subscribers"
                            checked={sendGridSync}
                            onChange={(e) => setSendGridSync(e)}
                          />
                          <div style={{ paddingTop: 'var(--p-space-4)' }}></div>
                          <Button
                            primary
                            onClick={createSendGridCampaign}
                            loading={submitLoader}
                            disabled={submitLoader}>
                            Save selection
                          </Button>
                        </>
                      ) : (
                        <p>
                          No SendGrid list found{' '}
                          <Link
                            onClick={() =>
                              handleSettingsLinkNewTab('https://mc.sendgrid.com/contacts')
                            }
                            external
                            monochrome>
                            Click Here
                          </Link>{' '}
                          to create list
                        </p>
                      )}
                    </FormLayout>
                  ) : (
                    <SkeletonPage>
                      <LegacyCard sectioned>
                        <SkeletonBodyText />
                      </LegacyCard>
                    </SkeletonPage>
                  )}
                </LegacyCard.Section>
              </LegacyCard>
            </>
          )}
          <Modal
            loading={removeLoader}
            open={removePopup}
            onClose={closeRemovePopup}
            title="Are you sure you want to disconnect?"
            primaryAction={{
              content: 'Yes',
              disabled: removeLoader,
              onAction: removeVendor
            }}
            secondaryActions={[
              {
                content: 'No',
                disabled: removeLoader,
                onAction: closeRemovePopup
              }
            ]}></Modal>
        </>
      )}
      <div style={{ marginTop: 'var(--p-space-8)' }}></div>
    </>
  );
};
