export const apiRoutes = {
  notifyCheck: 'notify/check',
  login: 'auth/login',
  authCheck: 'auth/check',
  onboarding: '/on-boarding',
  onboardingUpdate: '/on-boarding/update',
  onboardingStepUpdate: '/on-boarding/update/step',
  createFeedBack: 'feedback/create',
  mailChimpCallBack: 'mailchimp/callback',
  checkSubscription: 'auth/subscription',
  createSubscription: 'subscription/create',
  trialSubscription: 'subscription/trial',
  dashboardOverview: 'dashboard/summary',
  subscriptionTrial: 'subscription/trial',
  vendorList: 'vendor/list',
  vendorFind: 'vendor/find',
  vendorStatus: 'vendor/status-update',
  vendorDelete: 'vendor/delete',
  campaignStatus: 'vendor/campaign/status-update',
  campaignDelete: 'vendor/campaign/delete',
  checkVendor: 'vendor',
  mailChimpSaveToken: 'mailchimp/token',
  mailChimpLiveList: 'mailchimp/audience/list',
  sendMailchimpSave: 'mailchimp/campaign/save',
  sendGridConnect: 'sendGrid/connect',
  sendGridLiveList: 'sendGrid/campaign-list',
  sendGridCampaignSave: 'sendGrid/campaign/save',
  trackMixPanel: 'mixpanel'
};
