import React, { useMemo, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Provider } from '@shopify/app-bridge-react';
import { componentProps } from '../dto/types';
import Login from '../pages/login/login';
import { isShopifyEmbedded } from '@shopify/app-bridge/utilities';
import { checkAuthRequest } from '../utils/helper';

/**
 * A component to configure App Bridge.
 * @desc A thin wrapper around AppBridgeProvider that provides the following capabilities:
 *
 * 1. Ensures that navigating inside the app updates the host URL.
 * 2. Configures the App Bridge Provider, which unlocks functionality provided by the host.
 *
 * See: https://shopify.dev/apps/tools/app-bridge/react-components
 */
export const AppBridgeProvider: React.FC<componentProps> = ({ children }): JSX.Element => {
  const [search] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const history = useMemo(
    () => ({
      replace: (path: string) => {
        navigate(path, { replace: true });
      }
    }),
    [navigate]
  );

  const routerConfig = useMemo(() => ({ history, location }), [history, location]);

  // The host may be present initially, but later removed by navigation.
  // By caching this in state, we ensure that the host is never lost.
  // During the lifecycle of an app, these values should never be updated anyway.
  // Using state in this way is preferable to useMemo.
  // See: https://stackoverflow.com/questions/60482318/version-of-usememo-for-caching-a-value-that-will-never-change
  const [appBridgeConfig] = useState(() => {
    const host = new URLSearchParams(location.search).get('host') || window.__SHOPIFY_HOST;

    window.__SHOPIFY_HOST = host;

    return {
      host,
      apiKey: process.env.REACT_APP_SHOPIFY_API_KEY ?? '',
      forceRedirect: true
    };
  });

  if (!process.env.REACT_APP_SHOPIFY_API_KEY || !appBridgeConfig.host || !isShopifyEmbedded()) {
    const isAuthRequest: boolean = checkAuthRequest(search);

    if (isAuthRequest) {
      window.location.href = `${process.env.REACT_APP_API_URL}/auth?${search}`;
      return <></>;
    }

    return <Login />;
  }

  return (
    <Provider config={appBridgeConfig} router={routerConfig}>
      {children}
    </Provider>
  );
};
