import React from 'react';
import { Banner } from '@shopify/polaris';
import { useRouteError } from 'react-router-dom';

const ErrorElement: React.FC = () => {
  const error = useRouteError() as Error;

  const renderError = () => {
    return (
      <>
        <Banner title="Error 💥" status="critical">
          <p>{error.message}</p>
        </Banner>
      </>
    );
  };

  return renderError();
};

export default ErrorElement;
