/**
 * Method to check is current request is auth request or not
 * @public
 * @param {URLSearchParams} queryParams
 * @return {boolean} - True indicates that request is for shopify to auth
 * */
export const checkAuthRequest = (queryParams: URLSearchParams): boolean => {
  const authQueryParams = ['hmac', 'host', 'shop', 'timestamp'];
  const valid = authQueryParams.find((x: string) => !queryParams.has(x));
  return !valid;
};
