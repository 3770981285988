import React, { useCallback } from 'react';
import { AppProvider } from '@shopify/polaris';
import { useNavigate } from '@shopify/app-bridge-react';
import '@shopify/polaris/build/esm/styles.css';
import { useI18n } from '@shopify/react-i18n';
import enTranslations from '@shopify/polaris/locales/en.json';
import { LinkLikeComponentProps } from '@shopify/polaris/build/ts/latest/src/utilities/link/types';
import { componentProps } from '../dto/types';
const AppBridgeLink: React.FC<LinkLikeComponentProps> = ({
  url,
  children,
  external,
  ...rest
}): JSX.Element => {
  const navigate = useNavigate();
  const handleClick = useCallback(() => {
    navigate(url);
  }, [url]);

  const IS_EXTERNAL_LINK_REGEX = /^(?:[a-z][a-z\d+.-]*:|\/\/)/;

  if (external || IS_EXTERNAL_LINK_REGEX.test(url)) {
    return (
      <a target="_blank" rel="noopener noreferrer" href={url} {...rest}>
        {children}
      </a>
    );
  }

  return (
    <a onClick={handleClick} {...rest}>
      {children}
    </a>
  );
};

/**
 * Sets up the AppProvider from Polaris.
 * @desc PolarisProvider passes a custom link component to Polaris.
 * The Link component handles navigation within an embedded app.
 * Prefer using this vs any other method such as an anchor.
 * Use it by importing Link from Polaris, e.g:
 *
 * ```
 * import {Link} from '@shopify/polaris'
 *
 * function MyComponent() {
 *  return (
 *    <div><Link url="/tab2">Tab 2</Link></div>
 *  )
 * }
 * ```
 *
 * PolarisProvider also passes translations to Polaris.
 *
 */
export const PolarisProvider: React.FC<componentProps> = ({ children }): JSX.Element => {
  const [i18n, ShareTranslations] = useI18n({
    id: 'Polaris',
    fallback: enTranslations,
    translations(locale: string) {
      return import(`@shopify/polaris/locales/${locale}.json`).then(
        (dictionary) => dictionary && dictionary.default
      );
    }
  });
  return (
    <AppProvider i18n={i18n.translations} linkComponent={AppBridgeLink}>
      <ShareTranslations>{children}</ShareTranslations>
    </AppProvider>
  );
};
