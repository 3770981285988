import { QueryClient, QueryClientProvider, QueryCache, MutationCache } from 'react-query';
import React from 'react';
import { componentProps } from '../dto/types';

/**
 * Sets up the QueryClientProvider from react-query.
 * @desc See: https://react-query.tanstack.com/reference/QueryClientProvider#_top
 */
export const QueryProvider: React.FC<componentProps> = ({ children }): JSX.Element => {
  const client = new QueryClient({
    queryCache: new QueryCache(),
    mutationCache: new MutationCache()
  });

  return <QueryClientProvider client={client}>{children}</QueryClientProvider>;
};
