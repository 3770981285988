import React, { useEffect, useState } from 'react';
import { Text, Badge, Banner, Layout, Divider, LegacyCard } from '@shopify/polaris';
import { useRequest } from '../../../hooks/useRequest';
import { apiRoutes } from '../../../utils/api.routes';
import { ResponseDto } from '../../../dto/response';
import statusCodes from '../../../utils/status.codes';

export const Plan: React.FC = () => {
  const [trialDays, setTrialDays] = useState(null);
  //const [trialPercent, setTrialPercent] = useState(null);
  const { get } = useRequest();

  useEffect(() => {
    get(apiRoutes.trialSubscription).then((response: ResponseDto) => {
      if (response.status == statusCodes.SUCCESS) {
        setTrialDays(response.data.data.trialDays);
        //setTrialPercent(response.data.data.percentage);
      }
    });
  }, []);

  return (
    <>
      {trialDays != null && (
        <>
          <div style={{ marginTop: 'var(--p-space-2)' }}></div>
          <Banner status="warning">
            {trialDays > 1 ? (
              <p>
                {trialDays} days left of trial. Don&apos;t miss out on experiencing all our app has
                to offer.
              </p>
            ) : (
              <p>
                Trial is expiring today. Don&apos;t miss out on experiencing all our app has to
                offer.
              </p>
            )}
          </Banner>
          <div style={{ marginTop: 'var(--p-space-4)' }}></div>
        </>
      )}
      {/*<Banner status="info">*/}
      {/*  <p>*/}
      {/*    You are currently on the <strong>Development</strong> plan and you are billed monthly.*/}
      {/*  </p>*/}
      {/*</Banner>*/}
      <div style={{ marginTop: 'var(--p-space-6)' }}></div>
      <Layout>
        <Layout.Section oneThird>
          <LegacyCard sectioned>
            <div className="pricePlan">
              <Badge status="success">Currently Active</Badge>
              <Text variant="headingXl" as="h1">
                Basic
              </Text>
              <div style={{ marginTop: 'var(--p-space-4)' }}></div>
              <Text variant="bodySm" as="span">
                Starting at
              </Text>
              <Text variant="heading2xl" color="success" as="h2">
                $0.99
              </Text>
              <Text variant="bodySm" as="span">
                Billed Monthly
              </Text>
              <div style={{ marginTop: 'var(--p-space-4)' }}></div>
              <Divider borderStyle="base" />
              <div style={{ marginTop: 'var(--p-space-4)' }}></div>
              <Text variant="bodySm" as="p">
                Easy to Use
              </Text>
              <div style={{ marginTop: 'var(--p-space-4)' }}></div>
              <Text variant="bodySm" as="p">
                Integrate SendGrid
              </Text>
              <div style={{ marginTop: 'var(--p-space-4)' }}></div>
              <Text variant="bodySm" as="p">
                Integrate Mailchimp
              </Text>
              <div style={{ marginTop: 'var(--p-space-4)' }}></div>
              <Text variant="bodySm" as="p">
                Synchronize newsletter form emails
              </Text>
              <div style={{ marginTop: 'var(--p-space-4)' }}></div>
              <Text variant="bodySm" as="p">
                Prompt and responsive chat support
              </Text>
            </div>
          </LegacyCard>
          <div style={{ marginTop: 'var(--p-space-2)' }}></div>
        </Layout.Section>
        <Layout.Section oneThird></Layout.Section>
        <Layout.Section oneThird></Layout.Section>
      </Layout>
    </>
  );
};
