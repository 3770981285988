import React, { useEffect, useState } from 'react';
import { useRequest } from '../../../hooks/useRequest';
import { Button } from '@shopify/polaris';
import { apiRoutes } from '../../../utils/api.routes';
import { ResponseDto } from '../../../dto/response';

const MailChimp: React.FC = () => {
  const [mailChimpLink, setMailChimpLink] = useState('');
  const { get } = useRequest();

  useEffect(() => {}, []);

  const getMailChimpUrl = () => {
    get(apiRoutes.mailChimpCallBack).then((response: ResponseDto) => {
      const data = response.data.data;
      window.location.href = data.url;
    });
  };

  return (
    <div>
      <h1>Mail Chimp</h1>
      <Button onClick={() => getMailChimpUrl()}>Connect Mail Chimp</Button>
    </div>
  );
};

export default MailChimp;
