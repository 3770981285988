import React from 'react';
import { LocationOrHref, NavigationMenu } from '@shopify/app-bridge-react';

const Navigation: React.FC = () => {
  return (
    <NavigationMenu
      navigationLinks={[
        {
          label: 'Plan',
          destination: '/plan'
        },
        {
          label: 'Support',
          destination: '/support'
        }
      ]}
      matcher={(link, location: LocationOrHref) => {
        if (typeof location === 'string') {
          return link.destination === location;
        }
        return link.destination === location.pathname;
      }}
    />
  );
};

export default Navigation;
