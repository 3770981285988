const statusCodes = {
  SUCCESS: 200,
  BAD_REQUEST: 400,
  UN_AUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  VALIDATION_FAILED: 422,
  INTERNAL_SERVER_ERROR: 500
};

export default statusCodes;
