import React, { StrictMode } from 'react';
import { createRoot, Root } from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { I18nContext, I18nManager } from '@shopify/react-i18n';
import { I18nError } from '@shopify/react-i18n/build/ts/errors';

const i18nManager = new I18nManager({
  locale: 'en',
  fallbackLocale: 'en',
  onError(error: I18nError) {
    console.log(error);
  }
});

const container: HTMLElement | null = document.getElementById('root');
const root: Root = createRoot(container as Element);

root.render(
  <StrictMode>
    <I18nContext.Provider value={i18nManager}>
      <App />
    </I18nContext.Provider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
