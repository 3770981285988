import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { getSessionToken } from '@shopify/app-bridge/utilities';
import statusCodes from '../utils/status.codes';
import { Redirect } from '@shopify/app-bridge/actions';
import { Action } from '@shopify/app-bridge/actions/Navigation/Redirect';
import { useAppBridge } from '@shopify/app-bridge-react';
import { useToasts } from '../states/useToast';
import { ClientApplication } from '@shopify/app-bridge-core/client/types';

export const useAxios = (isNotify: boolean = true): AxiosInstance => {
  const appBridge: ClientApplication = useAppBridge();
  const { setToast } = useToasts();
  const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

  axiosInstance.interceptors.request.use((config) => {
    return getSessionToken(appBridge).then((token) => {
      if (config.headers) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    });
  });

  axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => {
      if (response.data.message && isNotify) {
        const status = response.data.statusCode != statusCodes.SUCCESS;
        notify(response.data.message, status, response.status);
      }
      return response;
    },
    (error: AxiosError) => {
      const response: AxiosResponse | undefined = error.response;
      if (response?.data.message && response?.status !== statusCodes.FORBIDDEN) {
        notify(response?.data.message, true, response.status);
      }

      if (
        response?.status === statusCodes.FORBIDDEN &&
        response?.data?.data?.confirmationUrl != undefined
      ) {
        Redirect.create(appBridge).dispatch(Action.REMOTE, {
          url: response?.data?.data?.confirmationUrl
        });
      }

      if (response?.status === statusCodes.UN_AUTHORIZED) {
        const search: string = new URL(window.location.href).searchParams.toString();
        Redirect.create(appBridge).dispatch(Action.REMOTE, {
          url: `${process.env.REACT_APP_API_URL}/auth?${search}`
        });
      }

      return Promise.reject(error);
    }
  );

  const notify = (message: string, isError = false, status = 200) => {
    setToast({
      message: message,
      isError: isError,
      status: status
    });
  };

  return axiosInstance;
};
