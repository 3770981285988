import React, { useEffect } from 'react';
import {
  Page,
  Text,
  Card,
  Form,
  Frame,
  Button,
  Layout,
  TextField,
  FormLayout,
  TextContainer
} from '@shopify/polaris';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { apiRoutes } from '../../utils/api.routes';

const Login: React.FC = () => {
  const [search] = useSearchParams();
  const [shopUrl, setShopUrl] = useState('');
  const [inputError, setInputError] = useState('');

  useEffect(() => {
    if (search.has('error')) {
      setInputError(search.get('error') ?? '');
    }
  }, [search]);

  const handleSubmit = () => {
    if (!shopUrl) {
      setInputError('Input must have the shop url');
      return;
    }
    window.location.href = `${process.env.REACT_APP_API_URL}/${apiRoutes.login}?shop=${shopUrl}`;
  };

  return (
    <Frame>
      <Page>
        <div style={{ marginTop: '150px' }}>
          <TextContainer>
            <Text variant="heading2xl" as="h1">
              Login to your account
            </Text>
          </TextContainer>
          <div style={{ marginTop: '50px' }}></div>
          <Layout>
            <Layout.Section secondary>
              <Card title="Cart Recovery With Ease" sectioned>
                <p>
                  Connect and Sync Your Shopify Newsletter with Top Email Marketing Services - Mailchimp, SendGrid and more.
                </p>
                <div style={{ marginTop: '15px' }}></div>
                <p>
                  New to Newsletter sync? <Button plain>Get started</Button>
                </p>
              </Card>
            </Layout.Section>
            <Layout.Section>
              <Card title="Login" sectioned>
                <Form onSubmit={handleSubmit}>
                  <FormLayout>
                    <TextField
                      value={shopUrl}
                      label="Shop URL"
                      type="text"
                      autoComplete="url"
                      onChange={(value: string) => setShopUrl(value)}
                      error={inputError}
                      helpText={
                        <span>
                          URL of your store. We will use this to validate access and then will
                          redirect.
                        </span>
                      }
                    />
                    <Button submit primary>
                      Continue to Login
                    </Button>
                  </FormLayout>
                </Form>
              </Card>
            </Layout.Section>
          </Layout>
        </div>
      </Page>
    </Frame>
  );
};

export default Login;
