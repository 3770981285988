import React from 'react';
import Loader from '../loader';
import { Outlet } from 'react-router-dom';
import LayoutToast from '../layout-toast';
import { Banners } from '../banner/banners';
import { FeedBack } from '../modal/feedback';
import Navigation from './navigation';
import { Frame } from '@shopify/polaris';

const AppLayout: React.FC = () => {
  return (
    <div className="shopwarpper">
      <div className="shopwarpper2">
        <Frame>
          <Navigation />
          <Banners />
          <Outlet />
          <LayoutToast />
        </Frame>
      </div>
      <Loader />
      <FeedBack />
    </div>
  );
};

export default AppLayout;
