import React from 'react';
import { Spinner } from '@shopify/polaris';
import { useLoader } from '../states/useLoader';

const Loader: React.FC = () => {
  const { showLoader } = useLoader();
  return showLoader ? <Spinner accessibilityLabel="loading..." size="large" /> : <></>;
};

export default Loader;
