import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AppBridgeProvider, PolarisProvider, QueryProvider } from './providers';
import Routes from './routes/web';

const App: React.FC = (): JSX.Element => {
  return (
    <PolarisProvider>
      <BrowserRouter>
        <AppBridgeProvider>
          <QueryProvider>
            <Routes />
          </QueryProvider>
        </AppBridgeProvider>
      </BrowserRouter>
    </PolarisProvider>
  );
};

export default App;
